import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Container from 'components/Container';
import ImageDisplayTemp from 'components/ImageDisplayTemp';
import Layout from 'components/Layout';
import Nav from 'components/Nav';
import SEO from 'components/SEO';

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.serif};
  text-align: center;
  ${({ theme }) => theme.sizes.f2};
`;

const MarkdownContainer = styled.div`
  p {
    margin: 10px 0;
    ${({ theme }) => theme.sizes.p};
  }
`;

export const TextWrapper = styled.div`
  margin: 80px auto 50px;
  max-width: 700px;
`;

function FurniturePageTemplate({ data, location }) {
  // eslint-disable-next-line
  const {
    description,
    image,
    gallery,
    galleryImageText,
    title,
  } = data.contentfulFurniture;
  const images = gallery.map(el => el.image);

  const seo = {
    description: description.description,
    imageUrl: image.file.url,
    title: `Burnt Creek Custom | ${title}`,
  };

  const text = (
    <MarkdownContainer
      style={{ textAlign: 'center' }}
      dangerouslySetInnerHTML={{
        __html: galleryImageText.childMarkdownRemark.html,
      }}
    />
  );
  return (
    <Layout>
      <SEO {...seo} />
      <Nav location={location} navTheme="dark" />
      <Container>
        <TextWrapper>
          <Title>{title}</Title>
        </TextWrapper>
        <ImageDisplayTemp images={images} text={text} />
      </Container>
    </Layout>
  );
}

FurniturePageTemplate.propTypes = {};

FurniturePageTemplate.defaultProps = {};

export const query = graphql`
  query($slug: String!) {
    contentfulFurniture(slug: { eq: $slug }) {
      description {
        description
      }
      image {
        file {
          url
        }
      }
      title
      galleryImageText {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        image {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid
          }
          title
        }
      }
    }
  }
`;

export default FurniturePageTemplate;
