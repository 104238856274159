import React from 'react';
// import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import { media } from 'styles/theme';

import styled from 'styled-components';

const ImageWrapper = styled.div`
  ${media.ns} {
    height: 400px;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  ${media.ns} {
    max-height: 400px;
    object-fit: contain;
    img {
      object-fit: contain !important;
    }
  }
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  ${media.ns} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Bottom = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 30px;
  max-width: 600px;
  margin: 0 auto;
`;

function ImageDisplayTemp({ images, text }) {
  return (
    <ScrollContainer>
      <Grid>
        {images.map((image, i) => (
          <ImageWrapper key={i}>
            <StyledGatsbyImage alt={image.title} fluid={image.fluid} />
          </ImageWrapper>
        ))}
      </Grid>
      <Bottom>{text}</Bottom>
    </ScrollContainer>
  );
}

ImageDisplayTemp.propTypes = {};

ImageDisplayTemp.defaultProps = {};

export default ImageDisplayTemp;
